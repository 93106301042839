import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { htmlParser } from '../helpers/htmlParser';

// eslint-disable-next-line
export const StudentTemplate = ({ date, image, heading, description }) => {
  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="project--info">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                {image && (
                  <div className="project-logo-container featured-thumbnail">
                    <PreviewCompatibleImage
                      className={'project-logo-img'}
                      imageInfo={{
                        image: image,
                        alt: `${heading}`,
                        width: image.childImageSharp.gatsbyImageData.width,
                        height: image.childImageSharp.gatsbyImageData.height
                      }}
                    />
                  </div>
                )}
                <div className="project-titles">
                  <h2>{heading}</h2>
                </div>
                <div className="description project-desc">
                  {htmlParser(description)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

StudentTemplate.propTypes = {
  date: PropTypes.any,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  description: PropTypes.string
};

const Student = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <StudentTemplate
        date={post.frontmatter.date}
        image={post.frontmatter.image}
        heading={post.frontmatter.heading}
        description={post.frontmatter.description}
      />
    </Layout>
  );
};

Student.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default Student;

export const pageQuery = graphql`
  query StudentByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(height: 240, quality: 100, layout: CONSTRAINED)
          }
        }
        date
        heading
        description
      }
    }
  }
`;
